import { useAssets } from 'js/utils/hooks/useAssetsBase'
import { useIcons } from 'js/utils/hooks/useIcons'
import { useNewAssets } from 'js/utils/hooks/useNewAssets'
import { useMemo } from 'react'

import prepareSimplifiedAssetList from 'src/js/assets/prepareSimplifiedAssetList'

const useFetchSimplifiedAssets = () => {
  const assetsData = useAssets()
  const iconsData = useIcons()
  const newAssetsData = useNewAssets()
  const dataObjects = {
    assetsData,
    iconsData,
    newAssetsData,
  }
  // build assets array.
  const assetsArr = useMemo(() => {
    return prepareSimplifiedAssetList(dataObjects)
  }, [dataObjects])

  return assetsArr
}

export default useFetchSimplifiedAssets
