// dependencies.
import React from 'react'
// components.
import PlatformDropdown, { platforms } from 'src/components/button/Download/PlatformDropdown'
// constants.
import {
  WEB3WALLETPAGE_DOWNLOAD_WEB3,
  WEB3WALLETPAGE_DOWNLOAD_MOBILE,
} from 'src/js/analytics/trackers'

// Styles & Images
import 'src/components/pages/web3-wallet/scss/HeaderSection.scss'

// Main component:
const HeaderSection = ({ data, location }) => {
  // Download links.
  const downloadDropdownProps = {
    colors: ['#A16BB3', '#420BE6'],
    trackerPage: 'Web3WalletPage',
    location,
  }

  const googleDownloadProps = {
    utmSource: 'exodus-website',
    utmCampaign: 'web3-wallet-page',
    utmContent: 'web3-wallet-page',
  }

  return (
    <header className="x__web3-wallet-page__header">
      <div
        className="x__web3-wallet-page__header__content web3-wallet-page-rellax"
        data-rellax-speed="3"
      >
        <h1 className="x__web3-wallet-page__header__heading">
          <span dangerouslySetInnerHTML={{ __html: data.h1 }} />
        </h1>

        <h2
          className="x__web3-wallet-page__header__subheading"
          dangerouslySetInnerHTML={{ __html: data.h2 }}
        />

        <div className="x__web3-wallet-page__header__actions">
          <PlatformDropdown
            platform={platforms.mobile}
            androidLinkProps={googleDownloadProps}
            sourceMobile={WEB3WALLETPAGE_DOWNLOAD_MOBILE}
            {...downloadDropdownProps}
          />
          <PlatformDropdown
            platform={platforms.web3}
            extensionLinkProps={googleDownloadProps}
            sourceWeb3={WEB3WALLETPAGE_DOWNLOAD_WEB3}
            {...downloadDropdownProps}
          />
        </div>
      </div>
    </header>
  )
}

export default HeaderSection
