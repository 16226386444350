// dependencies.
import React from 'react'
// components.
import FadeCarousel from 'components/carousel/FadeCarousel'

// Styles & Images
import 'src/components/pages/web3-wallet/scss/CarouselSection.scss'

// Partials:
const ChromeImage = ({ index, speed }) => (
  <span
    className={`x__web3-wallet-page__carousel-section__chrome x__web3-wallet-page__carousel-section__chrome--${index} web3-wallet-page-rellax`}
    data-rellax-speed={speed}
    role="presentation"
  />
)

// Main component:
const CarouselSection = ({ data }) => (
  <section className="x__web3-wallet-page__carousel-section">
    <div className="x__web3-wallet-page__carousel-section__content">
      <h2 className="x__web3-wallet-page__carousel-section__heading">{data.h2}</h2>
      <h3
        className="x__web3-wallet-page__carousel-section__subheading"
        dangerouslySetInnerHTML={{ __html: data.h3 }}
      />
    </div>

    <FadeCarousel items={data.items} />

    <ChromeImage index={1} speed={-1} />
    <ChromeImage index={2} speed={-1.5} />
  </section>
)

export default CarouselSection
