// dependencies.
import React, { useEffect } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

// Styles & Images
import 'src/components/pages/web3-wallet/scss/VideoSection.scss'

// Partials:
const PlayButton = () => (
  <i className="x__web3-wallet-page__video__preview__play-button">
    <svg width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4167 11.1496L0 22.1996V0.0996094L18.4167 11.1496Z"
        fill="white"
      />
    </svg>
  </i>
)

// Main component:
const CarouselSection = ({ data }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      import('src/plugins/fslightbox.js')
    }
  }, [])

  const videoParams = new URLSearchParams({ autoplay: '1', fs: '1' })

  const videoSrc = `https://www.youtube.com/embed/${data.videoId}?${videoParams.toString()}`

  return (
    <section className="x__web3-wallet-page__video" id="video-section">
      <div className="x__web3-wallet-page__video__content">
        <h4
          className="x__web3-wallet-page__video__subheading"
          dangerouslySetInnerHTML={{ __html: data.h4 }}
        />

        <a href={videoSrc} data-fslightbox className="x__web3-wallet-page__video__preview">
          <PlayButton />
          <GatsbyImage image={data.image} alt={data.h4} loading="lazy" />
        </a>
      </div>
    </section>
  )
}

export default CarouselSection
