// dependencies.
import React, { useState } from 'react'
// components.
import Button from 'components/button'
import FadeCarousel from 'components/carousel/FadeCarousel'
// utils.
import { useDimensions, useScrollToAnchor } from 'js/utils/hooks'

// Styles & Images
import 'src/components/pages/web3-wallet/scss/FeaturesSection.scss'

// Partials:
const ChromeImage = ({ index, speed }) => (
  <span
    className={`x__web3-wallet-page__features__chrome x__web3-wallet-page__features__chrome--${index} web3-wallet-page-rellax`}
    data-rellax-speed={speed}
    role="presentation"
  />
)

const PlayIcon = () => (
  <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10 6L0 12V0L10 6Z" fill="white" />
  </svg>
)

// Main component:
const CarouselSection = ({ data }) => {
  const [navToVideo, setNavToVideo] = useState(false)

  const { isMobile } = useDimensions()

  useScrollToAnchor(navToVideo, data.cta.path, 100)

  const handleClick = () => {
    setNavToVideo(true)
    setTimeout(() => setNavToVideo(false), 1000)
  }

  return (
    <section className="x__web3-wallet-page__features">
      <div className="x__web3-wallet-page__features__content">
        <h2 className="x__web3-wallet-page__features__heading">{data.h2}</h2>
        <h3
          className="x__web3-wallet-page__features__subheading"
          dangerouslySetInnerHTML={{ __html: data.h3 }}
        />
        {!isMobile && (
          <Button
            copy={data.cta.copy}
            size="largest"
            type="secondary"
            iconLeft={<PlayIcon />}
            onClick={handleClick}
          />
        )}
      </div>

      <FadeCarousel
        items={data.items}
        visibleItemsCount={1}
        chromes={
          <>
            <ChromeImage index={1} speed={-0.5} />
            <ChromeImage index={2} speed={-1} />
            <ChromeImage index={3} speed={-1.5} />
            <ChromeImage index={4} speed={-2} />
          </>
        }
      />
    </section>
  )
}

export default CarouselSection
