// dependencies.
import React, { useMemo } from 'react'
import classNames from 'classnames'
// components.
import AssetIcon from 'src/components/assets/shared/AssetIcon'
// utils.
import useFetchSimplifiedAssets from 'js/assets/hooks/useFetchSimplifiedAssets'
import useDimensions from 'js/utils/hooks/useDimensions'
// constants.
import { isUk } from 'src/constants'

// Styles & Images:
import 'src/components/pages/web3-wallet/scss/AssetsSection.scss'

// helpers:
const assetList = [
  'BNB',
  'ADA',
  'ALGO',
  'FTM',
  'AVAX',

  'SOL',
  'BTC',
  'ETH',
  'MATIC',

  'ARB',
  'OP',
  'RBTC',
  'LINK',
]

const itemsPerRow = [5, 4, 5]

const getAssetObjects = (assets, selectedList) => {
  // filter assets.
  const filteredAssets = assets.filter(({ ticker }) => selectedList.includes(ticker))

  // map keys in selectedList.
  const keyToIndexMap = {}
  selectedList.forEach((key, index) => {
    keyToIndexMap[key] = index
  })

  // order filteredAssets.
  const sortedAssets = [...filteredAssets].sort((a, b) => {
    const indexA = keyToIndexMap[a.ticker]
    const indexB = keyToIndexMap[b.ticker]
    return indexA - indexB
  })

  // delete duplicates.
  const uniqueAssets = []
  const seenKeys = new Set()

  sortedAssets.forEach((asset) => {
    if (!seenKeys.has(asset.ticker)) {
      uniqueAssets.push(asset)
      seenKeys.add(asset.ticker)
    }
  })

  return uniqueAssets
}

// Partials:
const IconWrapper = ({ href, title, children }) => {
  if (!isUk && href)
    return (
      <a className="x__web3-wallet-page__assets__asset-icon" href={href} title={title}>
        {children}
      </a>
    )
  return <span className="x__web3-wallet-page__assets__asset-icon">{children}</span>
}

const AssetIcons = ({ isMobile, assets, grid }) => {
  let startIndex = 0

  const rows = grid.map((rowCount) => {
    const endIndex = startIndex + rowCount
    const assetsSlice = assets.slice(startIndex, endIndex)

    let rowItems = assetsSlice.map(({ icon, name, ticker, link }, i) => (
      <IconWrapper key={`${name}-${ticker}-${i}`} href={`/${link}`} title={`${name} (${ticker})`}>
        <AssetIcon icon={icon} name={name} ticker={ticker} size="100" />
      </IconWrapper>
    ))

    if (rowCount > assetsSlice.length) {
      const emptyCount = rowCount - assetsSlice.length
      const emptyArray = Array.from({ length: emptyCount }, (_, i) => i + 1)
      rowItems = [
        ...rowItems,
        emptyArray.map((item) => (
          <IconWrapper key={`more-${item}`} title="More assets coming soon">
            <AssetIcon icon={null} alt="More assets coming soon" size="100" />
          </IconWrapper>
        )),
      ]
    }

    startIndex = endIndex

    return rowItems
  })

  return (
    <div className="x__web3-wallet-page__assets__wrapper">
      {rows.map((content, i) => {
        const speed = i === 0 || i === rows.length - 1 ? (isMobile ? '0.1' : '0.8') : 0

        return (
          <div
            key={i}
            className={classNames('x__web3-wallet-page__assets__row', {
              'x__web3-wallet-page__assets__row--short': content.length < 5,
              'web3-wallet-page-rellax': speed,
            })}
            data-rellax-speed={speed}
          >
            {content}
          </div>
        )
      })}
    </div>
  )
}

// Main component:
const AssetsSection = ({ data }) => {
  const { assets } = useFetchSimplifiedAssets()
  const { isMobile } = useDimensions()

  const selectedAssets = useMemo(() => {
    return getAssetObjects(assets, assetList)
  }, [assets, assetList])

  return (
    <section className="x__web3-wallet-page__assets">
      <AssetIcons assets={selectedAssets} grid={itemsPerRow} isMobile={isMobile} />

      <div className="x__web3-wallet-page__assets__content">
        <h2 className="x__web3-wallet-page__assets__heading">{data.h2}</h2>
      </div>
    </section>
  )
}

export default AssetsSection
